$(function(){

  $(".back").on("click", function(){
    $(".inner").removeClass("open");
    $(".back").removeClass("d-flex");
    return false;
  });

  $(".headLink").on("click", function(){

    if($(".menu-item-container").hasClass("open")){

      // if .inner follows, we should pop it up
      if($(this).next().hasClass("inner")){

        // open the sub menu by applying to dd parent
        $(this).closest(".dd").toggleClass("open");

        return false;
      }
    }

    if($(this).next().hasClass("inner")){
      return false;
    }

    return true;
  });


  document.addEventListener('click', event => {
    if(!event.target.matches(".menu-item-container", ".menu-item-container *")){
      $("html").removeClass("mm-open");
      $(".menu-item-container").removeClass("open");
    }
    return false;
  });


  $(".ham").on("click", function(){
    $("html").addClass('mm-open');
    $(".menu-item-container").addClass("open");
    return false;
  });


});

// gulp needs to make the base webp


// work out why js in not minifies when pushed to destination 
// move all js into assets and add to git ignore

// then get the header working. 

// Once done finish above the fold and then move all
//  the styles into a file to minify and import this generated file in php to the header

// proceed with the rest of the page layout. (review the sass vars)
